import { FC } from 'react'
import { errorPlaceholderSvgCx } from './ErrorLayout.css'

export interface ErrorPlaceholderSvgProps {}
export const ErrorPlaceholderSvg: FC<ErrorPlaceholderSvgProps> = () => (
  <svg
    id="Group_838"
    data-name="Group 838"
    xmlns="http://www.w3.org/2000/svg"
    className={errorPlaceholderSvgCx}
    viewBox="0 0 413.662 465.024"
    data-testid="error-placeholder-svg"
  >
    <circle
      id="Ellipse_46"
      data-name="Ellipse 46"
      cx="179.154"
      cy="179.154"
      r="179.154"
      transform="translate(55.353 0)"
      fill="#f2f2f2"
    />
    <path
      id="Path_2459"
      data-name="Path 2459"
      d="M360.836,112.524H32.65a1.275,1.275,0,0,1,0-2.548H360.836a1.275,1.275,0,0,1,0,2.548Z"
      transform="translate(-9.253 -32.406)"
      fill="#cacaca"
    />
    <rect
      id="Rectangle_539"
      data-name="Rectangle 539"
      width="115.4"
      height="24.677"
      rx="12.339"
      transform="translate(136.675 123.061)"
      fill="#fff"
    />
    <path
      id="Path_2460"
      data-name="Path 2460"
      d="M123.02,244.445H330.96A12.338,12.338,0,0,1,343.3,256.784h0a12.338,12.338,0,0,1-12.339,12.339H123.02a12.339,12.339,0,0,1-12.339-12.339h0A12.339,12.339,0,0,1,123.02,244.445Z"
      transform="translate(-32.614 -72.03)"
      fill="#fff"
    />
    <path
      id="Path_2461"
      data-name="Path 2461"
      d="M123.02,314.418H330.96A12.338,12.338,0,0,1,343.3,326.757h0A12.338,12.338,0,0,1,330.96,339.1H123.02a12.339,12.339,0,0,1-12.339-12.339h0A12.338,12.338,0,0,1,123.02,314.418Z"
      transform="translate(-32.614 -92.649)"
      fill="#fff"
    />
    <path
      id="Path_2462"
      data-name="Path 2462"
      d="M70.668,633.582l-.489-.048a56.8,56.8,0,0,1-41.042-24.417,55.108,55.108,0,0,1-6.218-12.346l-.149-.416.44-.036c5.253-.423,10.649-1.314,13.046-1.738L20.8,589.344l-.1-.459A10.772,10.772,0,0,1,25.238,577.7a10.957,10.957,0,0,1,12.276-.208c1.683,1.075,3.443,2.14,5.145,3.169,5.849,3.538,11.9,7.2,16.433,12.2a34.332,34.332,0,0,1,8.2,28.394Z"
      transform="translate(-6.031 -169.644)"
      fill="#f2f2f2"
    />
    <path
      id="Path_2463"
      data-name="Path 2463"
      d="M171.3,634.584h7.618l3.625-29.386H171.3Z"
      transform="translate(-50.476 -178.332)"
      fill="#a0616a"
    />
    <path
      id="Path_2464"
      data-name="Path 2464"
      d="M170.919,652.838h23.427v-.3a9.119,9.119,0,0,0-9.119-9.118h0l-4.279-3.247-7.984,3.247h-2.045Z"
      transform="translate(-50.364 -188.64)"
      fill="#2f2e41"
    />
    <path
      id="Path_2465"
      data-name="Path 2465"
      d="M84.741,607.125l6.093,4.571,20.534-21.33-8.994-6.747Z"
      transform="translate(-24.971 -171.974)"
      fill="#a0616a"
    />
    <path
      id="Path_2466"
      data-name="Path 2466"
      d="M77.674,622.583l18.74,14.059.178-.237a9.119,9.119,0,0,0-1.822-12.766h0L93.3,618.474l-8.335-2.194-1.636-1.227-5.65,7.531Z"
      transform="translate(-22.888 -181.236)"
      fill="#2f2e41"
    />
    <path
      id="Path_2467"
      data-name="Path 2467"
      d="M113.433,441.491s.421,11.472.951,20.671c.087,1.509-3.448,3.148-3.356,4.789.061,1.089.725,2.148.787,3.283.065,1.2-.852,2.259-.787,3.5.064,1.211,1.11,2.6,1.173,3.839.679,13.337,3.142,29.3.676,33.767-1.215,2.2-16.706,32.674-16.706,32.674s8.631,12.946,11.1,8.631c3.257-5.7,28.359-32.366,28.359-37.458s5.807-43.2,5.807-43.2l4.049,22.558,1.857,4.469-.583,2.624,1.2,3.541.065,3.5,1.168,6.508s-3.514,50.7-1.535,52.112,11.6,3.964,12.731,1.418,8.472-52.914,8.472-52.914,1.184-23.081,2.466-44.535c.074-1.245.949-2.75,1.017-3.976.08-1.424-.475-3.274-.4-4.655.08-1.584.783-2.7.851-4.217.524-11.72-2.418-25.991-3.355-27.4-2.83-4.241-5.376-7.632-5.376-7.632s-38.113-12.522-48.3.2Z"
      transform="translate(-28.339 -123.137)"
      fill="#2f2e41"
    />
    <path
      id="Path_2468"
      data-name="Path 2468"
      d="M158.878,282.841l-15.109-3.022-7.051,9.569h0c-13.156,12.936-13.354,24.353-11.006,42.654v25.685l-1.7,17.218s-6.018,12.587.189,15.517,46.974,2.681,50.866,1.511.519-2.606-.5-8.058c-1.92-10.241-.636-5.3-.5-8.562,1.894-46.773-2.518-65.975-2.966-71.023L163.41,290.4l-4.533-7.554Z"
      transform="translate(-35.771 -82.454)"
      fill="#3f3d56"
    />
    <path
      id="Path_2469"
      data-name="Path 2469"
      d="M269.982,333.793a6.109,6.109,0,0,1-9.158,1.966l-32.629,20.718.094-11.28,31.7-18.394a6.142,6.142,0,0,1,10,6.99Z"
      transform="translate(-67.242 -95.693)"
      fill="#a0616a"
    />
    <path
      id="Path_2470"
      data-name="Path 2470"
      d="M151.55,302.438l-3.715-.394a9.829,9.829,0,0,0-8.873,3.873,9.578,9.578,0,0,0-1.675,3.521l0,.01a9.82,9.82,0,0,0,3.709,10.214l12.83,9.459c9,12.166,25.926,20.238,45.775,26.8l30.853-19.162-10.913-13.217L198.377,334.88,166.92,310.529l-.018-.015L154.929,302.8Z"
      transform="translate(-40.372 -88.987)"
      fill="#3f3d56"
    />
    <circle
      id="Ellipse_47"
      data-name="Ellipse 47"
      cx="17.619"
      cy="17.619"
      r="17.619"
      transform="translate(100.383 157.73)"
      fill="#a0616a"
    />
    <path
      id="Path_2471"
      data-name="Path 2471"
      d="M167.614,273.59l-.427.012c-.061.158-.127.314-.2.468Z"
      transform="translate(-49.205 -80.618)"
      fill="#2f2e41"
    />
    <path
      id="Path_2472"
      data-name="Path 2472"
      d="M174.732,249.3a3.119,3.119,0,0,0,.126.511A2.4,2.4,0,0,0,174.732,249.3Z"
      transform="translate(-51.488 -73.46)"
      fill="#2f2e41"
    />
    <path
      id="Path_2473"
      data-name="Path 2473"
      d="M174.9,223.017c-.725,2.252-1.372-.6-3.758-.091-2.87.618-6.21.406-8.56-1.353a13.261,13.261,0,0,0-12.148-1.852c-4.021,1.423-10.759,2.426-11.807,6.561-.364,1.437-.509,3-1.392,4.188a14.681,14.681,0,0,1-3.009,2.437c-3.44,2.707-.811,10.4.817,14.46s5.363,7.023,9.469,8.539a33.45,33.45,0,0,0,12.523,1.61,9.5,9.5,0,0,0-.19-6.1c-.658-2.146-1.633-4.216-1.909-6.444s.329-4.758,2.2-6c1.717-1.144,4.51-.447,5.163,1.4-.381-2.312,1.961-4.55,4.377-4.944,2.593-.422,5.186.514,7.772.98S176.117,225.988,174.9,223.017Z"
      transform="translate(-39.056 -64.533)"
      fill="#2f2e41"
    />
    <circle
      id="Ellipse_48"
      data-name="Ellipse 48"
      cx="36.057"
      cy="36.057"
      r="36.057"
      transform="translate(159.543 234.481) rotate(-85.269)"
      fill="#0058ff"
    />
    <path
      id="Path_2474"
      data-name="Path 2474"
      d="M284.073,263.93,274.6,273.4l-9.471-9.471a3.926,3.926,0,1,0-5.552,5.552l9.471,9.471-9.471,9.471a3.926,3.926,0,0,0,5.552,5.552l9.471-9.471,9.471,9.471a3.926,3.926,0,0,0,5.552-5.552l-9.471-9.471,9.471-9.471a3.926,3.926,0,1,0-5.552-5.552Z"
      transform="translate(-76.151 -77.433)"
      fill="#fff"
    />
    <path
      id="Path_2475"
      data-name="Path 2475"
      d="M252.593,240.971a6.108,6.108,0,0,1-4.921,7.97l-7.639,37.888-8.075-7.877,8.67-35.607a6.142,6.142,0,0,1,11.964-2.374Z"
      transform="translate(-68.351 -69.756)"
      fill="#a0616a"
    />
    <path
      id="Path_2476"
      data-name="Path 2476"
      d="M143.48,297.831l-2.856,2.408a9.829,9.829,0,0,0-3.348,9.084,9.579,9.579,0,0,0,1.381,3.646l.006.009a9.82,9.82,0,0,0,9.938,4.4l15.708-2.71c15.013,1.926,32.552-4.7,51.026-14.481l7.533-35.53-17.092-1.275L199.309,286.5l-39.349,5.841-.023,0-13.855,3.3Z"
      transform="translate(-40.413 -77.609)"
      fill="#3f3d56"
    />
    <path
      id="Path_2477"
      data-name="Path 2477"
      d="M192.511,658.289.837,658.506a.84.84,0,0,1,0-1.68l191.674-.217a.84.84,0,0,1,0,1.68Z"
      transform="translate(0 -193.482)"
      fill="#cacaca"
    />
    <g id="Group_837" data-name="Group 837" transform="translate(34.082 51.781)">
      <ellipse
        id="Ellipse_49"
        data-name="Ellipse 49"
        cx="5.965"
        cy="6.098"
        rx="5.965"
        ry="6.098"
        fill="#3f3d56"
      />
      <ellipse
        id="Ellipse_50"
        data-name="Ellipse 50"
        cx="5.965"
        cy="6.098"
        rx="5.965"
        ry="6.098"
        transform="translate(20.605)"
        fill="#3f3d56"
      />
      <ellipse
        id="Ellipse_51"
        data-name="Ellipse 51"
        cx="5.965"
        cy="6.098"
        rx="5.965"
        ry="6.098"
        transform="translate(41.211)"
        fill="#3f3d56"
      />
      <path
        id="Path_2478"
        data-name="Path 2478"
        d="M146.54,84.864a.7.7,0,0,1-.5-.212l-4.025-4.114a.7.7,0,0,1,0-.986l4.025-4.114a.705.705,0,1,1,1.008.986L143.5,80.045l3.542,3.621a.705.705,0,0,1-.5,1.2Z"
        transform="translate(-75.869 -73.947)"
        fill="#3f3d56"
      />
      <path
        id="Path_2479"
        data-name="Path 2479"
        d="M157.809,84.864a.705.705,0,0,1-.5-1.2l3.542-3.621-3.542-3.621a.705.705,0,0,1,1.008-.986l4.024,4.114a.7.7,0,0,1,0,.986l-4.024,4.114a.7.7,0,0,1-.5.212Z"
        transform="translate(-80.375 -73.947)"
        fill="#3f3d56"
      />
      <path
        id="Path_2480"
        data-name="Path 2480"
        d="M452.842,74.414h-7.484a1.544,1.544,0,0,0-1.545,1.545v7.491a1.544,1.544,0,0,0,1.545,1.545h7.484a1.55,1.55,0,0,0,1.552-1.545V75.959A1.55,1.55,0,0,0,452.842,74.414Z"
        transform="translate(-164.86 -73.708)"
        fill="#3f3d56"
      />
      <path
        id="Path_2481"
        data-name="Path 2481"
        d="M426.842,74.414h-7.484a1.544,1.544,0,0,0-1.545,1.545v7.491a1.544,1.544,0,0,0,1.545,1.545h7.484a1.55,1.55,0,0,0,1.552-1.545V75.959A1.55,1.55,0,0,0,426.842,74.414Z"
        transform="translate(-157.198 -73.708)"
        fill="#3f3d56"
      />
      <path
        id="Path_2482"
        data-name="Path 2482"
        d="M477.342,74.914h-7.484a1.544,1.544,0,0,0-1.545,1.545v7.491a1.544,1.544,0,0,0,1.545,1.545h7.484a1.55,1.55,0,0,0,1.552-1.545V76.459A1.55,1.55,0,0,0,477.342,74.914Z"
        transform="translate(-172.079 -73.856)"
        fill="#3f3d56"
      />
      <path
        id="Path_2483"
        data-name="Path 2483"
        d="M295.415,78.954H235.6a1.883,1.883,0,1,0,0,3.766h59.819a1.883,1.883,0,0,0,0-3.766Z"
        transform="translate(-102.949 -75.046)"
        fill="#3f3d56"
      />
    </g>
  </svg>
)
